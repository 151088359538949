<template>
	<div class='NoneCodeUserEdit'>
		<div  class="top_info">
			<span class="title">批量修改工号</span>
			<div>
				<a-button  class='pointerCancel' @click='go_back'>暂不修改
				</a-button>&nbsp;
				<a-button  class='pointerCancel' type="primary" @click='handleOk'>批量保存
				</a-button>
			</div>
		</div>
		<div>
			<div style="width: 100%;">
				<div class="FieldSettingsBox" style="position: relative;padding-top: 42px;" >
					<div class="FieldSettingsBoxTitle FieldSettingsBoxHeader">
						<span>
			
						</span>
						<span>
							姓名
						</span>
						<span>部门</span>
						<span>岗位</span>
						<span>工作性质</span>
						<span>入职日期</span>
						<span style="color: #666;">工号</span>
					</div>
					<div style="height:730px;overflow-y: auto;">
						<div class="FieldSettingsBoxTitle" v-for="(it,index) in all_list" :key="it.id">
							<span>
								{{index+1}}
							</span>
							<span>
								{{it.username}}
							</span>
							<span>
								{{it.department_name}}
							</span>
							<span>
								{{it.role_name}}
							</span>
							<span>
								{{it.nature_name}}
							</span>
							<span>
								{{it.entry_time}}
							</span>
							<span>
								<a-input v-model:value="it.drp_code" />
							</span>
						</div>
					</div>
			
				</div>
			
			</div>
		   
		      
		</div>
	</div>

</template>

<script>
  
    import {
getBatchNoneCodeUser,batchSaveUserCode
    } from '@/api/enterprise.js'
    import {
        message,
        TreeDataItem,
        TreeDragEvent,
        DropEvent,
        Modal
    } from 'ant-design-vue';

    export default {
        components: {
          
        },
        data() {
            return {
               all_list:[]
            }

        },
        created() {
           
            this.getIndustry()
        },
        methods: {
			go_back(){
				this.$router.back()
			},
            getIndustry() {
                getBatchNoneCodeUser().then(res=>{
                    this.all_list = res.data.list
                })
            },
            handleOk() {
            	batchSaveUserCode({
            		data: {
            			batch_value: this.all_list
            		}
            	}).then(res => {
            		message.success('操作成功');
            		this.$router.back()
            	})
            },
        },

    }
</script>

<style lang="less" scoped>
	@import url("../../assets/less/app.less");
    @import 'info.less';
	.NoneCodeUserEdit{
		background:#fff;margin-left:16px;
		position: relative;
		padding:16px;
		padding-top:0px;
		margin-top:16px;
	}
    .table_list_body {
        margin: 0;
        padding: 0;
    }
    .check{
        color: @cq_color;
        cursor: pointer;
    }
    .del{
        color: @cq_error;
    }
	.FieldSettingsBox{
		width: 100%;
	}
	.FieldSettingsBoxTitle {
		display: flex;
		width: 100%;
		padding: 8px 12px;
		border-bottom: 1px solid @cq_line_e;
	
		span:nth-child(1) {
			flex: .3;
			margin-left: 8px;
		}
		span:nth-child(2),span:nth-child(3),span:nth-child(4) {
			flex: 1;
		}
			
			span:nth-child(5),span:nth-child(6),span:nth-child(7) {
				flex: .8;
			}
		
	
		
	}
	.w100{
		width: 100px;
	}
	
	.top_info{
		display: flex;
		// position:absolute;
		// top:0;
		margin: 16px;
		.title{
			font-size: 16px;
			font-weight: 600;
		}
		div{
			flex: 1;
			text-align: right;
		}
	}
	.FieldSettingsBoxHeader{
		position: absolute;top:0px;left: 0px;z-index: 99;
	}
</style>
